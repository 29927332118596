<template>
  <b-row>
    <b-col>
      <h4>{{ this.$t('updater.propertiesToUpdate') }}</h4>
      <b-form-group label="" v-slot="{ ariaDescribedby }">
        <b-form-checkbox-group
          id="checkbox-group-2"
          v-model="selectedFieldsToChange"
          :aria-describedby="ariaDescribedby"
          name="flavour-2"
          class="mb-3"
        >
          <b-form-checkbox class="bg-green" v-for="column in venueWineOptions" :value="column" :key="column.value" @change="changedValue(column)" :disabled="deleteWine" style="display: flex; align-items: center;">
                {{ column }}
          </b-form-checkbox>
        </b-form-checkbox-group>
      </b-form-group>
     
      <b-row class="mapping" v-if="notFoundColumns.length > 0">
        <b-col>
          <h4>Nicht gefundene Spalten zuordnen:</h4>

          <b-row v-for="column in notFoundColumns" :key="column">
            <b-col style="display: flex; align-items: center; margin-left: 3px;width: 100px;">
              {{ column }}
            </b-col>
            <b-col>
              <img class="arrow-icon" src="../../assets/img/icon-dropdown.svg">
            </b-col>
            
            <b-col style="width: 120px;">
              <b-form-select class="v2" v-model="rowMappingObject[column]" :options="csvColumns" @change="triggerReload = !triggerReload"></b-form-select>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

    
      <b-row v-if="wineSupplierNotFound.length > 0 && selectedFieldsToChange.indexOf('Bezugsquelle') > -1">
        <b-col>
          <h4>{{ this.$t('updater.wineSupplierNotFound') }}</h4>
          {{ wineSupplierNotFound.join(', ') }}
        </b-col>
      </b-row>
    </b-col>
    <b-row class="mb-3 mt-3 more-options">
      <h4>{{ this.$t('updater.moreOptions') }}</h4>
      <b-form-checkbox  v-model="deleteWine" @change="selectedFieldsToChange=[]">
        <span>
          {{ this.$t('updater.deleteWine') }}
        </span>
      </b-form-checkbox>
      <b-form-checkbox v-model="addToProduct"><span>{{ this.$t('updater.addToProduct') }}</span></b-form-checkbox>
      <b-form-checkbox v-model="useAsDifference"><span>{{ this.$t('updater.dontOverwrite') }}</span></b-form-checkbox>
    </b-row>


    <b-row v-if="!buttonDisabled">
        <h4>{{ this.$t('updater.columnsToImport') }}</h4>
        <b-alert class="updater" :show=true>{{ Object.keys( rowMappingObject).map((key) => { return "Spalte " + rowMappingObject[key] + " zu "+ key}).join(', ') }}</b-alert>
      </b-row>

      
    <ButtonFooter :backButtonVisible="true" :okButtonDisabled="buttonDisabled"
           :backButtonLabel="this.$t('general.buttons.back')" :okButtonLabel="this.$t('general.buttons.forward')" :okAction="() => finishedMapping(rowMappingObject, useAsDifference, addToProduct, deleteWine)" :backAction="handleBackFromStep1"/>
  </b-row>
</template>
<script>
import ButtonFooter from '@/components/modals/ButtonFooter.vue'

export default {
  name: "TargetMapping",
  components: {
    ButtonFooter
  },
  props: {
    csvColumns: Array,
    wineSupplierNotFound: Array,
    finishedMapping: Function,
    rowMappingObjectStart: Object
  },
  data() {
    return {
      deleteWine: false,
      selectedFieldsToChange: [],
      venueWineOptions: ["Anzahl", "VK", "Regal", "Artikelnummer", "Kommentar", "Kassennotiz", "Bezugsquelle", "EK"],
      rowMappingObject: {},
      notFoundColumns: [],
      triggerReload: false,
      useAsDifference: true,
      showAlert: false,
      addToProduct: false
    }
  },
  created() {
    this.rowMappingObject = this.rowMappingObjectStart;
    this.addValuesFromMappingObject()
  },
  methods: {
    handleBackFromStep1() {
      this.$emit('backFromStep1');
    },
    changedValue(value) {
      this.$nextTick(() => {
        if (this.selectedFieldsToChange.indexOf(value) > -1) {
        
          if (this.csvColumns.indexOf(value) == -1){
            this.notFoundColumns.push(value);
            this.rowMappingObject[value] = '';
          } else {
            this.rowMappingObject[value] = value;
          }
        } else {
          this.notFoundColumns = this.notFoundColumns.filter((item) => item != value);
          delete this.rowMappingObject[value];
        }
        this.triggerReload = !this.triggerReload;
      })
    },
    addValuesFromMappingObject(){
      console.log(this.rowMappingObject)
      var keys = Object.keys(this.rowMappingObject)
      for (var i = 0; i < keys.length; i++) {
        if (this.rowMappingObject[keys[i]] != null && this.rowMappingObject[keys[i]] != undefined && this.rowMappingObject[keys[i]] != '') {
          this.selectedFieldsToChange.push(keys[i]);
        }
      }
    }
  },
  computed: {
    buttonDisabled() {
      this.triggerReload 
      var keys = Object.keys(this.rowMappingObject)
      for (var i = 0; i < keys.length; i++) {
        if (this.rowMappingObject[keys[i]] == null || this.rowMappingObject[keys[i]] == undefined || this.rowMappingObject[keys[i]] == '') {
          return true;
        }
      }
      return this.selectedFieldsToChange.length == 0 || (this.wineSupplierNotFound.length > 0 && this.selectedFieldsToChange.indexOf('Bezugsquelle') > -1);
    }
  },
  watch: {
    rowMappingObjectStart: {
      handler: function (val) {
        this.rowMappingObject = val;
        this.addValuesFromMappingObject()
      },
      deep: true
    },
    addToProduct: function (val) {
      if (val) {
        this.useAsDifference = false;
      }
    },
    useAsDifference: function (val) {
      if (val) {
        this.addToProduct = false;
      }
    }
  }
}
</script>
<style scoped>

</style>